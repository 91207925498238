import type { Argument } from "./types.ts"

import { createConfig, http, type CreateConnectorFn } from "wagmi"
import { base, mainnet } from "wagmi/chains"
import { coinbaseWallet, walletConnect } from "wagmi/connectors"

const connectors = [
  connectorWithIcon(
    coinbaseWallet({
      appName: "IYK Consumer",
    }),
    "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='%230053FF' d='M8.51 11a3 3 0 1 1 2.96-3.5h3.03a6 6 0 1 0 0 1h-3.03A3 3 0 0 1 8.5 11Z' /%3E%3C/svg%3E",
  ),
  connectorWithIcon(
    walletConnect({ projectId: "4ef063ba762451ba2e7ec5ed5b575f28" }),
    "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='%23202020' d='M4.37 5.09a5.94 5.94 0 0 1 8.26 0l.28.26a.3.3 0 0 1 0 .41l-.94.92a.15.15 0 0 1-.2 0l-.39-.37a4.14 4.14 0 0 0-5.76 0l-.4.4a.15.15 0 0 1-.22 0l-.94-.92a.28.28 0 0 1 0-.41l.3-.3Zm10.2 1.9.85.82c.1.1.1.29 0 .4l-3.78 3.7a.3.3 0 0 1-.41 0L8.55 9.27a.07.07 0 0 0-.1 0L5.77 11.9a.3.3 0 0 1-.41 0L1.59 8.21a.28.28 0 0 1 0-.4l.83-.82a.3.3 0 0 1 .41 0L5.51 9.6c.03.02.08.02.1 0L8.3 6.99a.3.3 0 0 1 .42 0l2.67 2.62c.03.03.08.03.1 0l2.69-2.62a.3.3 0 0 1 .4 0Z' /%3E%3C/svg%3E",
  ),
]

export const wagmiConfig = createConfig({
  chains: [mainnet, base],
  connectors,
  transports: {
    [mainnet.id]: http(),
    [base.id]: http(),
  },
})

function connectorWithIcon<T extends CreateConnectorFn>(connectorFn: T, icon: string) {
  return (cfg: Argument<T, 0>) => {
    const connector = connectorFn(cfg)

    return {
      ...connector,
      icon,
    }
  }
}
